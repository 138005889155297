import * as React from "react";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <div className="container flex flex-col gap-40 py-40 uppercase">
        <header>
          <h1>index</h1>
        </header>

        <main>
          <h2>page transition</h2>
          <section>
            <h3>simple structure</h3>
            <nav>
              <ul>
                <li>
                  <Link to="/simpletr/tr01">to page</Link>
                </li>
              </ul>
            </nav>
          </section>
        </main>
      </div>
    </>
  );
};

export default IndexPage;
